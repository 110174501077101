import React from 'react'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import ContentWrapper from '../components/ContentWrapper'
import PrincipleGrid from '../components/PrincipleGrid'
import PrincipleCard from '../components/PrincipleCard'

const PrinciplesPage = ({ data: { principleInfo, principles } }) => (
  <Layout title="Browse principles">
    <Hero
      heading="Browse principles"
      heroImage={principleInfo.icon && principleInfo.icon}
      description={principleInfo.categoryPageDefinition}
    />
    <ContentWrapper>
      <section>
        <PrincipleGrid>
          {principles.nodes.map((principle) => (
            <PrincipleCard
              key={principle.slug}
              slug={principle.slug}
              name={principle.name}
              icon={principle.heroIcon}
            />
          ))}
        </PrincipleGrid>
      </section>
    </ContentWrapper>
  </Layout>
)
PrinciplesPage.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}

export const query = graphql`
  {
    principleInfo: contentfulContentTypeInfo(contentType: { eq: "principle" }) {
      categoryPageDefinition {
        json
      }
      icon {
        file {
          url
        }
      }
    }
    principles: allContentfulPrinciple(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        name
        slug
        heroIcon {
          file {
            url
          }
          description
        }
      }
    }
  }
`

export default PrinciplesPage
