import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { Link } from 'gatsby'
import { BREAKPOINT, COLOR, FONT, SPACING, SHADOW } from '../constants'

const PrincipleCardLink = styled(Link)`
  background-color: ${COLOR.WHITE};
  text-decoration: none;
  border: 1px solid ${COLOR.GRAY.LIGHT};
  border-radius: 10px;
  box-shadow: ${SHADOW.CARD};

  &:hover {
    border: 1px solid ${COLOR.TEAL.MEDIUM};
  }
`
const ImageContainer = styled.div`
  background-color: ${COLOR.BLUE.LIGHT};
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  padding: 7rem 3.5rem;

  @media (min-width: ${BREAKPOINT.S}) {
    padding: 3.5rem;
  }
  img {
    margin-bottom: 0;
    max-height: 120px;
  }
`

const PrincipleCardArticle = styled.article`
  max-width: 100%;
`

const TitleContainer = styled.div`
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING.S};
`
const PrincipleTitle = styled.p`
  font-family: ${FONT.FAMILY.BODY};
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.BOLD};
  letter-spacing: 0%;
  line-height: 20px;
  color: ${COLOR.GRAY.DARK};
  text-decoration: underline;
  margin-bottom: 0;
  text-align: center;
`

const PrincipleCard = ({ slug, name, icon }) => (
  <PrincipleCardLink key={slug} to={`/principles/${slug}`} aria-label={name}>
    <PrincipleCardArticle>
      <ImageContainer>
        <img src={icon.file.url} alt={icon.description} />
      </ImageContainer>
      <TitleContainer>
        <PrincipleTitle>{name}</PrincipleTitle>
      </TitleContainer>
    </PrincipleCardArticle>
  </PrincipleCardLink>
)
PrincipleCard.propTypes = {
  slug: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
  icon: propTypes.shape({
    file: propTypes.shape({
      url: propTypes.string,
    }),
    description: propTypes.string.isRequired,
  }).isRequired,
}

export default PrincipleCard
